<template>
  <login-left>
    <div class="login-left-nor">
      <div class="login-left-text">成为会员您可以</div>
      <div class="login-left-img">
        <div class="img-item">
          <div class="img-box">
            <img src="~@/assets/pc/images/login/login-check.png" alt="">
          </div>
          <span>查阅项目解读</span>
        </div>
        <div class="img-item">
          <div class="img-box">
            <img src="~@/assets/pc/images/login/login-push.png" alt="">
          </div>
          <span>接收项目推送</span>
        </div>
        <div class="img-item">
          <div class="img-box">
            <img src="~@/assets/pc/images/login/login-matching.png" alt="">
          </div>
          <span>进行项目匹配</span>
        </div>
        <div class="img-item">
          <div class="img-box">
            <img src="~@/assets/pc/images/login/login-manage.png" alt="">
          </div>
          <span>管理申报资料</span>
        </div>
      </div>
    </div>
  </login-left>
</template>

<script>
import loginLeft from './loginLeft';
export default {
  name: 'loginLeftNormal',
  components: {
    loginLeft
  }
};
</script>

<style scoped>
 .login-left-nor {
   padding-top: 84px;
 }
 .login-left-text {
   margin-left: 88px;
   width: 113px;
   height: 17px;
   font-size: 16px;
   font-weight: bold;
   font-style: italic;
   color: #7498C6;
 }
 .login-left-img{
   display: flex;
   flex-wrap: wrap;
   margin-top:33px;
   margin-left: 48px;
 }
 .login-left-img .img-item{
   display: flex;
   flex-wrap: wrap;
   justify-content: center;
   width: 84px;
   height: 92px;
 }
 /*第一第三项与第二第四项相隔41px*/
 .login-left-img .img-item:nth-child(odd){
   margin-right: 41px;
 }
 /*第三第四项与第一第二项相隔37px*/
 .login-left-img .img-item:nth-child(n+3){
   margin-top: 37px;
 }
 .login-left-img .img-item .img-box{
   /*display: flex;*/
   /*justify-content: center;*/
   /*align-items: center;*/
   position: relative;
   margin-bottom: 13px;
   width: 67px;
   height: 67px;
   border-radius: 34px;
   background-color: #bcd6f7;
 }
 .login-left-img .img-item:nth-child .img-box img{
   background-size: 49px 49px;
 }
 .login-left-img .img-item:nth-child(1) .img-box img{
   position: absolute;
   top: 13px;
   left: 10px;
 }
 .login-left-img .img-item:nth-child(2) .img-box img{
   position: absolute;
   top: 15px;
   left: 8px;
 }
 .login-left-img .img-item:nth-child(3) .img-box img{
   position: absolute;
   top: 13px;
   left: 9px;
 }
 .login-left-img .img-item:nth-child(4) .img-box img{
   position: absolute;
   top: 13px;
   left: 8px;
 }
 .login-left-img .img-item span{
   font-size: 14px;
   font-family: Microsoft YaHei;
   font-weight: 400;
   color: #999999;
 }
</style>
