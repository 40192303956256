<template>
    <div class="login-left-comp">
      <slot></slot>
    </div>
</template>

<script>
export default {
  name: 'loginLeft'
};
</script>

<style scoped>
.login-left-comp {
  width: 300px;
  /*由于左侧区域目前需求并没有高度上的变化，而是要随着右侧区域的高度变化而变化2021-11-11，故注释*/
  /*height: 480px;*/
  background: #f5faff;
  border-radius: 5px;
}
</style>
